@import "../../../../style/partials/variables";
@import "../../../../style/partials/mixins";

.summery {
    display: flex;
    flex-direction: column;
    gap: $base-padding;
    width: 100%;
    
    @include desktop {
        width: 48%;
    }

    &__container {
        background-color: white;
        padding: $base-padding;
        display: flex;
        gap: $base-padding;
        border-radius: $base-border-radius;
        box-shadow: 0px 5px 42px 2px rgba(114, 80, 149, 0.1);
        height: 200px;

        @include tablet {
            height: 280px;
        }
    }

    &__sub {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: $base-padding;

        &-title {
            margin: 0;
            font-size: 13px;
            text-align: center;
            font-weight: bold;
        }

        &-data {
            width: 100%;
            height: 100%;
            box-shadow: 0px 5.00846px 42.0711px 2.00338px rgba(114, 80, 149, 0.1);
            border-radius: $base-border-radius;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 42px;
            font-weight: bold;
            color: $purple;
        }
    }
}