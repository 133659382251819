.user-dropdown12 {
  position: absolute;
  top: 48px;
  /* right: 0px; */
  background-color: #fbf1df;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: 85px;
  overflow: hidden;
}
