@import "../../../../style/partials/variables";
@import "../../../../style/partials/mixins";

.group-data {
    display: flex;
    gap: $base-padding;
    background-color: $white;
    padding: $base-padding;
    border-radius: $base-border-radius;
    box-shadow: 0px 7.50311px 16.882px rgba(0, 0, 0, 0.08);
    flex-direction: column;

    @include desktop { 
        flex-direction: row;
    }

    &__chart-container {
        background-color: $accent;
        border-radius: $base-border-radius;

        @include desktop {
            width: 45%;
        }
    }

    &__members {
        background-color: #FAFAFA;
        padding: calc($base-padding * 2);
        display: flex;
        flex-direction: column;
        gap: calc($base-padding * 2);
        max-height: 300px;
        overflow-y: auto;

        @include custom-scroll;

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-list {
            display: flex;
            flex-direction: column;
            gap: $base-padding;
        }

        @include desktop {
            width: 65%;
        }
    }

    &__member {
        display: flex;
        gap: $base-padding;
        justify-content: space-between;

        &-left {
            display: flex;
            gap: $base-padding;
            align-items: center;
        }

        &-right {
            display: flex;
            gap: calc($base-padding * 2);
            align-items: center;
            font-size: 12px;
            color: $text;
            font-weight: bold;
        }

        &-date {
            margin: 0;
            font-size: 12px;
            color: $text;
            font-weight: bold;
        }

        &-name {
            margin: 0;
            font-size: 12px;
            color: $text;
            font-weight: bold;
        }

        &-thumbnail-container {
            width: 40px;
            height: 40px;
            border-radius: 60px;
            overflow: hidden;
        }

        &-thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}