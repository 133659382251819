@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Poppins:wght@300;400;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

h3 {
    font-size: 18px;
}