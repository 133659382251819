@import 'variables';

/* =================== Responsive Breakpoints Handler */

@mixin desktop {
    @media (min-width: $desktop) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $tablet) {
        @content;
    }
}

/* =================== Reusable Components */

@mixin page-wrapper {
    padding: ($base-padding * 2) ($base-padding * 2.5);

    @include tablet {
        padding: ($base-padding * 2);
    }

    @include desktop {
        width: $desktop;
        margin: 0 auto;
    }
}

@mixin custom-scroll {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    
    &::-webkit-scrollbar-track {
        background: $white;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $gray;
        border-radius: $base-border-radius;
        cursor: pointer;

        &:hover {
            cursor: pointer;
            background: $purple;
        }
    }
}

// Dashboard Page

@mixin dashboard-page {
    flex-grow: 1;
    overflow-y: hidden;
    background-color: #fbf8fe;
    display: flex;
}