@import "../../style/partials/variables";
@import "../../style/partials/mixins";

.settings {
    @include dashboard-page;

    &__intro {
        margin-bottom: $base-padding * 2;

        &-title {
            margin: 0 0 8px 0;
            color: $purple;
            font-size: 22px;
            font-weight: 400;
        }

        &-welcome {
            color: $accent2;
        }
    }

    &__content {
        gap: calc($base-padding * 2);
        padding: calc($base-padding * 2);
        border-radius: $base-border-radius;
        box-shadow: 0px 7.50311px 16.882px rgba(0, 0, 0, 0.08);
        background-color: $white;

        @include desktop {
            width: 1000px;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        gap: calc($base-padding * 2);
    }

    &__top {
        display: flex;
        justify-content: space-between;
        padding: calc($base-padding * 1.5);
        background-color: $accent;
        border-radius: $base-border-radius;

        &-image-container {
            width: 150px;
            height: 150px;
            border: 3px solid $accent2;
            border-radius: 530px;
            overflow: hidden;
            position: relative;
        }

        &-profile-pic {
            position: relative;
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &-edit {
                position: absolute;
                bottom: 5px;
                right: 10px;
                background-color: $white;
                padding: 2px 5px 1px 5px;
                border-radius: 50px;
                border: 2px solid $accent2;
                cursor: pointer;

                &:hover {
                    background-color: $accent;
                }
            }
        }

        &-connection {
            height: 50px;
            width: 140px;
            background-color: $white;
            padding: 12px 16px;
            border-radius: $base-border-radius;
            cursor: pointer;
            position: relative;

            &-image {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            &-connected {
                position: absolute;
                font-size: 25px;
                top: -12px;
                right: -5px;
                color: $purple;
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: calc($base-padding * 2);
        align-items: flex-end;

        @include desktop {
            flex-direction: row;
        }

        &-title {
            color: $purple;
        }

        &-side-1 {
            width: 100%;
            @include desktop {
                width: 50%;
            }

            display: flex;
            gap: calc($base-padding * 2);
            flex-direction: column;
        }
        
        &-side-2 {
            width: 100%;
            @include desktop {
                width: 50%;
            }
        }

        &-security {
            display: flex;
            flex-direction: column;
            gap: calc($base-padding * 2);
        }

        &-personal {
            display: flex;
            flex-direction: column;
            gap: calc($base-padding * 2);
        }

        &-password {
            display: flex;
            flex-direction: column;
            gap: calc($base-padding * 2);

            @include desktop {
                flex-direction: row;
            }
        }
    }
}