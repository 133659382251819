@import "../../style/partials/variables";
@import "../../style/partials/mixins";

.sidebar {
    width: 300px;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: $base-border-radius;
    display: none;
    flex-direction: column;
    gap: calc($base-padding * 2);
    align-self: flex-start;

    @include tablet {
        display: flex;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 65px 0 0 0;
    }

    &__menu {
        display: flex;
        flex-direction: column;

        &-item {
            display: flex;
            gap: $base-padding;
            align-items: center;
            padding: calc($base-padding / 1.3) calc($base-padding * 2);
            margin: 0;
            color: $gray;

            &--active {
                background-color: $accent;
                color: $purple;
            }

            &:hover {
                background-color: $accent;
                color: $purple;
            }
        }
    }

    &__footer {
        margin-top: auto;
        margin-bottom: 20px;

        &-item {
            display: flex;
            gap: $base-padding;
            align-items: center;
            padding: calc($base-padding / 1.3) calc($base-padding * 2);
            margin: 0;
            color: $gray;
            cursor: pointer;

            &:hover {
                color: $purple;
            }
        }
    }
}