$slide-count: 5;
$slide-width: 30rem;

html {
  box-sizing: border-box;
  font-size: 10px;
  @media (max-width: 425px) {
    font-size: 8px;
  }
}

body {
  font-size: 1.5rem;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: inherit;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.translate-y-24 {
  --tw-translate-y: 6rem;
}
.-translate-y-5,
.translate-y-24 {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.c-flex {
  display: flex;
}
.z-0 {
  z-index: 0;
}
.relative {
  position: relative;
}

.font-medium {
  font-weight: 500;
}
.text-1xl {
  font-size: 23px;
  line-height: 27px;
}
.mb-12 {
  margin-bottom: 3rem;
}

.text-opacity-30 {
  --tw-text-opacity: 0.3;
}
.text-\[black\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.font-medium {
  font-weight: 500;
}
.c-text-11xl {
  font-size: 187px;
  line-height: 219px;
}
.text-center {
  text-align: center;
}
.c-flex-grow {
  flex-grow: 1;
}
.mr-14 {
  margin-right: 3.5rem;
}
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

.carousel__wrap {
  margin-top: 10%;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
}

.carousel__inner {
  height: 40rem;
  position: relative;
  width: calc(#{$slide-width * 3});
}

.carousel__container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: calc(#{($slide-count + 0.5) * $slide-width * 2});
}

.carousel__slide-item {
  display: inline-block;
  height: $slide-width;
  margin: 0;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s;
  width: $slide-width;
}

.carousel__slide-item-img-link {
  cursor: zoom-in;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
    width: 100%;
  }

  &::after {
    align-items: center;
    background: rgba(black, 0.5);
    color: white;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    width: 100%;
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    img {
      transform: scale(1.3);
    }
  }
}

.carousel-slide-item__body {
  bottom: -2.5rem;
  height: 10%;
  position: absolute;

  h4 {
    margin: 0.7rem 0 0;
    text-transform: uppercase;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 0.7rem 0 0;
  }
}

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &--prev {
    left: -10rem;
  }

  &--next {
    right: -10rem;
  }
}

.carousel__btn-arrow {
  border: solid black;
  border-width: 0 0.4rem 0.4rem 0;
  height: 6rem;
  padding: 3px;
  width: 6rem;
  z-index: 10;

  &--left {
    transform: rotate(135deg);
  }

  &--right {
    transform: rotate(-45deg);
  }
}

.carousel__dots {
  display: inline-block;
  left: 50%;
  margin-top: 2rem;
  position: absolute;
  transform: translateX(-50%);

  .dot {
    background: #ccc;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: 2rem;
    margin: 0 0.3rem;
    outline: none;
    transform: scale(0.5);
    width: 2rem;

    &.active {
      background: black;
    }
  }
}

.heading-translate {
  transform: translate(0px, 100px);
}
