@import "../../style/partials/variables";
@import "../../style/partials/mixins";

.forgot-password {
    &__wrapper {
        @include page-wrapper;
        display: flex;
        flex-direction: column;
        gap: calc($base-padding * 6);
        margin-top: $base-padding;

        @include tablet {
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0;
        }
    }

    &__image {
        &-container {
            display: none;
            width: 50%;
            height: 600px;

            @include tablet {
                width: 48%;
                display: block;
            }
        }

        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: $base-padding;
        justify-content: center;
        width: 100%;

        @include tablet {
            width: 50%;
        }
    }

    &__verified {
        display: flex;
        flex-direction: column;
        gap: calc($base-padding / 6);
        justify-content: center;
        width: 50%;

        &-title {
            margin: 0;
        }
    }

    &__info {
        font-size: 14px;
        color: $gray;
    }
}