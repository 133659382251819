@import "../../style/partials/variables";
@import "../../style/partials/mixins";

.not-found {
    &__wrapper {
        @include page-wrapper;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: calc($base-padding * 2);
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: calc($base-padding * 2);
        align-items: center;

        @include desktop {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__image-container {
        width: 100%;

        @include tablet {
            width: 600px;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__back {
        display: inline-flex;
        gap: calc($base-padding / 2);
        color: $accent2;
        align-items: center;
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;

        &:hover {
            color: $purple;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: $base-padding;

        &-title {
            font-weight: bold;
            font-size: 100px;
            color: $purple;

            @include desktop {
                font-size: 120px;
            }
        }

        &-sub {
            font-size: 40px;
            color: $accent2;

            @include desktop {
                font-size: 60px;
            }
        }
    }
}