@import "../../../../style/partials/variables";
@import "../../../../style/partials/mixins";

.recently-played {
  display: flex;
  flex-direction: column;
  gap: $base-padding;
  width: 100%;

  @include desktop {
    width: 48%;
  }

  &__data {
    display: none;
    gap: $base-padding;
    overflow-x: auto;

    @include custom-scroll;

    @include tablet {
      display: inline-flex;
    }

    &-link {
      width: 100%;
      height: 100%;
    }
  }

  &__card {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: $base-border-radius;
    background-color: $white;
    width: 100%;
    height: 280px;
    overflow: hidden;
  }

  &__card-header {
    background-color: $accent;
    width: 100%;
    padding: $base-padding;
    display: flex;
    justify-content: center;
  }

  &__card-data {
    flex-grow: 1;
    display: flex;
    gap: calc($base-padding / 2);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 $base-padding;
  }

  &__card-title {
    color: $text;
    font-weight: bold;
    margin: 0;
    text-align: center;
    font-size: 14px;
  }

  &__card-description {
    color: #8695a0;
    font-size: 14px;
    margin: 0;
  }

  &__swiper {
    width: 250px;
    height: 300px;

    @include tablet {
      display: none;
    }
  }

  &__swiper-slide {
    border-radius: $base-border-radius;
    background-color: $white;

    &-link {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      border-radius: $base-border-radius;
      background-color: $white;
    }
  }
}
