@import "../../style/partials/variables";
@import "../../style/partials/mixins";

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1000;

    padding: ($base-padding) ($base-padding);

    @include tablet {
        padding: $base-padding ($base-padding * 2);
    }

    &__right {
        display: none;
        gap: calc($base-padding * 3);
        align-items: center;

        @include tablet {
            display: flex;
        }
    }

    &__left {
        display: flex;
        gap: calc($base-padding * 2);
        align-items: center;
    }

    &__user-group {
        display: flex;
        gap: $base-padding;
        align-items: center;
        position: relative;
    }

    &__user-name {
        display: flex;
        gap: $base-padding;
        align-items: center;
        color: $purple;

        &-text {
            margin: 0;
        }

        &:hover {
            cursor: pointer;
            color: $secondary;
        }
    }

    &__search {
        width: 350px;
        display: none;
        background-color: #a7a3ff;

        @include desktop {
            display: block;
        }
    }

    &__menu {
        display: flex;
        gap: $base-padding;
        align-items: center;

        &-item {
            color: $purple;
            text-decoration: none;

            &:hover {
                color: $accent2;
            }
        }
    }

    &__icons {
        display: flex;
        gap: $base-padding;

        &:hover {
            color: $accent2;
        }
    }

    &__user {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 30px;

        &-thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &--mobile {
            @include tablet {
                display: none;
            }
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        margin-right: 25px;
        width: 50px;

        &__image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @include tablet {
            margin-right: 0;
            width: 60px;
        }
    }
}

.user-dropdown {
    position: absolute;
    top: 70px;
    right: 0px;
    background-color: $accent;
    border-radius: $base-border-radius;
    display: flex;
    flex-direction: column;
    min-width: 150px;
    overflow: hidden;

    &__item {
        color: $purple;
        font-size: 16px;
        margin: 0;
        border-bottom: 1px solid $accent2;
        padding: calc($base-padding / 2) $base-padding;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            cursor: pointer;
            color: $accent2;
        }
    }
}

.menu-mobile {
    @include tablet {
        display: none;
    }

    &__lable {
        color: $lightgray;
        font-size: 12px;
    }

    &__list {
        display: flex !important;
        flex-direction: column;
        gap: calc($base-padding / 3)
    }

    &__item {
        text-decoration: none;
        color: $white;
    
        &:hover {
            color: $secondary;
        }

        &--icon {
            display: inline-flex;
            gap: calc($base-padding);
            align-items: center;
        }
    }

    &__devider {
        margin: ($base-padding * 1.5) 0;
        color: $lightgray;
    }

    &__cta {
        margin-top: $base-padding;
    }
}

.bm-burger-button {
    @include tablet {
        display: none;
    }

    position: absolute;
    width: 25px;
    height: 25px;
    right: 16px;
    top: 25px;
    color: $purple;
}

.bm-burger-bars {
    background: $purple;
}

.bm-burger-bars-hover {
    background: $secondary;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-cross {
    background: $accent;
}

.bm-menu-wrap {
    @include tablet {
        display: none;
    }

    position: fixed;
    height: 100%;
    top: 0;
}

.bm-menu {
    @include tablet {
        display: none;
    }

    background: $purple;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    z-index: 9999;
    height: 100vh !important;
}

.bm-morph-shape {
    fill: $purple;
}

.bm-item-list {
    padding: 0.8em;
    height: auto !important;
}

.bm-item {
    display: inline-block;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
}