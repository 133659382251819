@import "../../style/partials/variables";
@import "../../style/partials/mixins";

.search-results {
    &__wrapper {
        @include page-wrapper;
        display: flex;
        flex-direction: column;
        gap: calc($base-padding * 1.5);
        align-items: flex-start;
    }

    &__intro {
        margin-bottom: $base-padding;

        &-title {
            margin: 0 0 8px 0;
            color: $purple;
            font-size: 22px;
            font-weight: 400;
        }

        &-query {
            color: $accent2;
        }
    }

    &__list {
        display: flex;
        gap: $base-padding;
        flex-wrap: wrap;
        width: 100%;

        @include tablet {
            width: 600px;
        }

        &--posts {
            flex-direction: column;
        }
    }

    &__user {
        display: flex;
        gap: $base-padding;
        align-items: center;
        background-color: $accent;
        padding: calc($base-padding / 2) $base-padding calc($base-padding / 2) calc($base-padding / 2);
        border-radius: 50px;

        &-image-container {
            width: 35px;
            height: 35px;
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}