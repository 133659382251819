/* =================== Colors */

// Colors
$black: #222222;
$white: #ffffff;
$accent: #fbf1df;
$accent2: #a48532;
$purple: #dab876;
$gray: #bbbbbb;
$lightgray: #dadada;
$secondary: #3a2d47;
$text: #0f3f62;

/* =================== Others */

$desktop: 1280px;
$tablet: 768px;

$base-padding: 16px;
$desktop-padding: 8px;

$base-border-radius: 5px;
