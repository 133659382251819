@import "../../../../style/partials/variables";
@import "../../../../style/partials/mixins";

.recent-posts {
    display: flex;
    flex-direction: column;
    gap: $base-padding;
    width: 100%;
    overflow-y: auto;
    height: 100%;

    @include custom-scroll;
    
    @include desktop {
        width: 48%;
    }

    &__container {
        background-color: white;
        padding: $base-padding;
        display: flex;
        flex-direction: column;
        gap: $base-padding;
        border-radius: $base-border-radius;
        box-shadow: 0px 5px 42px 2px rgba(114, 80, 149, 0.1);
        justify-content: center;
        align-items: center;
    }

    &__data {
        margin: 0;
    }
}