@import "../../../../style/partials/variables";
@import "../../../../style/partials/mixins";

.team {
    background-color: $white;
    border-radius: $base-border-radius;
    padding: $base-padding;
    display: flex;
    flex-direction: column;
    gap: calc($base-padding * 2);
    box-shadow: 0px 5px 42px 2px rgb(114 80 149 / 10%);

    @include desktop {
        width: 650px;
    }

    &__settings {
        display: flex;
        gap: calc($base-padding / 2);
        align-items: center;
        
        &-owner {
            display: flex;
            flex-direction: column;
            text-align: right;
        }

        &-add {
            color: $purple; 
            cursor: pointer;
        }

        &-delete {
            color: rgb(221, 96, 96);
            cursor: pointer;
        }

        &-code {
            color: $gray;
            cursor: pointer;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__name {
        margin: 0;
        font-weight: bold;
        color: $purple;
    }

    &__owner {
        margin: 0;
        font-size: 15px;
        color: $gray;
    }

    &__win {
        margin: 0;
        font-size: 15px;
        color: $gray;

        &-cash {
            color: rgb(74, 153, 74);
        }
    }

    &__members {
        display: flex;
        flex-wrap: wrap;
        gap: $base-padding;
        align-items: center;
    }

    &__member {
        display: flex;
        background-color: $accent;
        padding: calc($base-padding / 2) $base-padding calc($base-padding / 2) calc($base-padding / 2);
        border-radius: 50px;
        gap: calc($base-padding / 2);
        align-items: center;
        font-size: 14px;

        &-image-container {
            width: 20px;
            height: 20px;
            border-radius: 50px;
            overflow: hidden;
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__add {
        font-size: 25px;
        margin: 0;
        color: $purple;
        cursor: pointer;
    }
}