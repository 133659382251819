.horizontal-slider {
  @apply w-full;
}

.horizontal-slider-thumb {
  cursor: pointer;
  z-index: 1;
  background: #ffcc66;
  border: 1px solid rgba(7, 7, 7, 0.1);
  border-radius: 54px;
}

.horizontal-slider-track {
  @apply bg-yellow-100;
}

.horizontal-slider .horizontal-slider-track {
  height: 11px;
  border-radius: 80px;
  top: 6px;
}

.horizontal-slider .horizontal-slider-thumb {
  width: 36px;
  outline: none;
  height: 25px;
}
